import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";

function Layout({ children, type = false }) {
  useEffect(() => window.scroll(0, 0), []);

  return (
    <>
      <Header type={type} />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
