import React from "react";
import Layout from "../components/partials/layout";
import AboutUsSection from "../components/elements/about-us-section";
import AboutSection from "../components/elements/about-section";
import AboutExpert from "../images/about-ekip.svg";
import AboutTherapy from "../images/about-terapi.svg";
import AboutTreatment from "../images/about-tedavi.svg";
import res from "../images/about-page.jpg";

import InfoCard from "../components/elements/info-card";
import PageHeader from "../components/elements/page-header";
import { useTranslation } from "react-i18next";

function AboutView() {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageHeader
        page={t("global_header_about")}
        home={t("global_header_home")}
        text={"-" + t("global_header_about")}
        image="about-image"
      />
      <div className="mb-10  md:-mt-32 md:mb-24  -mt-14">
        <AboutUsSection type={true} />
      </div>

      <div className="bg-lightPrimary px-5 md:px-28 pt-16 md:mb-16 mb-5">
        <div className="md:container  md:mx-auto text-main">
          <div className=" mb-10 ">
            <div className="text-center relative  md:mx-auto md:w-1/3">
              <h3 className="text-2xl md:text-3xl font-bold mb-10">
                {t("about_mission")}
              </h3>
            </div>
            <p className="text-center md:text-lg pb-20 ">
              {t("global_about_us_section_text_2")}
            </p>
          </div>
        </div>
      </div>
      <div className="  px-5 md:px-28 pt-16 md:mb-16 mb-5">
        <div className="md:container  md:mx-auto text-main">
          <div className="pb-16 ">
            <div className="text-center   relative  md:mx-auto md:w-1/3">
              <h3 className="text-2xl md:text-3xl font-bold mb-10">Galeri</h3>
            </div>
            <div className="md:flex items-center md:flex-wrap justify-center ">
              <section className="overflow-hidden text-gray-700">
                <div className=" ">
                  <div className="md:flex flex-wrap -m-1 md:-m-2">
                    <div className="flex flex-wrap md:w-1/2">
                      <div className="md:w-1/2 p-1  md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg  hover:shadow-lg"
                          src="assets/images/IMG_1113.jpg"
                        />
                      </div>
                      <div className="md:w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1114.jpg"
                        />
                      </div>
                      <div className="w-full p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1128.jpg"
                        />
                      </div>
                    </div>
                    <div className="md:flex flex-wrap md:w-1/2">
                      <div className="w-full p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1137.jpg"
                        />
                      </div>
                      <div className="md:w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1121.jpg"
                        />
                      </div>
                      <div className="md:w-1/2 p-1 md:p-2 ">
                        <img
                          alt="gallery"
                          className="block object-cover object-center  	 w-full h-full rounded-lg"
                          src="assets/images/IMG_1123.jpg"
                        />
                      </div>
                    </div>
                    <div className="md:flex  flex-wrap">
                      <div className="md:w-2/3 p-1 md:p-2 ">
                        <img
                          alt="gallery"
                          className=" object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1116.jpg"
                        />
                      </div>
                      <div className="md:w-1/3 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className=" object-cover object-center w-full h-full rounded-lg"
                          src="assets/images/IMG_1117.jpg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightPrimary  px-5 md:px-28 pt-16 md:mb-16 mb-5">
        <div className="md:container  md:mx-auto text-main">
          <div className=" mb-10 ">
            <div className="text-center relative  md:mx-auto md:w-1/3">
              <h3 className="text-sm font-bold  mb-5">{t("about_title")}</h3>
              <h2
                className="text-2xl md:text-3xl font-bold mb-10 "
                dangerouslySetInnerHTML={{ __html: t("about_little_title") }}
              />

              <span className="about-line-long"></span>
            </div>
            <p className="text-center md:text-lg ">{t("about_text")}</p>
          </div>
          <div className="md:flex  md:justify-between md:items-center ">
            <AboutSection
              image={AboutExpert}
              title={t("about_section_1_title")}
              text={t("about_section_1_text")}
            />
            <AboutSection
              image={AboutTherapy}
              title={t("about_section_2_title")}
              text={t("about_section_2_text")}
            />
            <AboutSection
              image={AboutTreatment}
              title={t("about_section_3_title")}
              text={t("about_section_3_text")}
            />
          </div>
        </div>
      </div>
      <div className="md:container md:mx-auto">
        <div className="px-5 md:text-center text-main mb-14  md:w-1/2 md:mx-auto">
          <div className=" md:flex md:items-center md:justify-center font-bold md:text-3xl mb-4 text-2xl ">
            <h2
              className="md:pr-2 "
              dangerouslySetInnerHTML={{ __html: t("about_container_title") }}
            />
          </div>
        </div>
        <div className="md:flex mb-16">
          <InfoCard
            title={t("about_info_card_title_1")}
            text={t("about_info_card_text_1")}
            color="bg-purple text-white"
          />
          <InfoCard
            title={t("about_info_card_title_2")}
            text={t("about_info_card_text_2")}
            color="bg-primary text-white"
          />
          <InfoCard
            title={t("about_info_card_title_3")}
            text={t("about_info_card_text_3")}
            color="bg-white text-main"
          />
        </div>
      </div>
    </Layout>
  );
}

export default AboutView;
