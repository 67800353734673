import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import BlogItemImage from "../images/blog-item-image.jpg";
import { useParams } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import axios from "axios";
import moment from "moment";
import "moment/locale/tr";

export default function BlogDetailView() {
  const params = useParams();
  const { t } = useTranslation();
  const activeLanguage = i18n.language;

  const [blog, setBlog] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "blogs/" + params.id)
      .then((res) => {
        if (res && res.data.status) {
          const data = res.data.data;

          const doctorData = {
            title: data.title,
            created_at: data.created_at,
            content: data.content,
            description: data.description,
            original: process.env.REACT_APP_API_URL + data.original,
          };
          setBlog(doctorData);
        } else {
          console.log("error");
          setBlog({});
        }
      })
      .catch((err) => {
        setBlog({});
        console.error(err);
      });
  }, [activeLanguage]);

  return (
    <Layout>
      <div className="md:container md:mx-auto px-5 mb-5">
        <div className="mt-10 font-bold text-center text-3xl mb-4 text-main">
          <h2>{blog.title}</h2>
        </div>
        <div className="flex justify-center items-center  mb-10">
          <FaRegCalendarAlt className="text-primary mr-2 " />
          <p className="font-semibold text-main">
            {moment(blog.created_at).format("DD MMMM YYYY")}
          </p>
        </div>

        <div className="flex justify-center items-center  mb-10">
          <p className=" text-main">{blog.description}</p>
        </div>
        <div className="">
          <div className=" w-full flex justify-center h-72  mb-16 ">
            <img width="full" src={blog.original} alt={blog.title} />
          </div>
        </div>
        <p
          className="mb-10"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </div>
    </Layout>
  );
}
