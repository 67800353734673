import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import BlogItem from "../components/elements/blog-item";
import PageHeader from "../components/elements/page-header";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/tr";
import axios from "axios";
import i18n from "i18next";

export default function BlogView() {
  const { t } = useTranslation();
  const [blogs, setBlogs] = useState([]);
  const activeLanguage = i18n.language;
  moment.locale(activeLanguage);

  const getDatas = async () => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + "blogs");

      if (result && result.data) {
        const data = result.data;

        if (data.status) {
          const blogDatas = data.data.filter(
            (x) => x.lang_code === activeLanguage
          );
          setBlogs(blogDatas);
        } else {
          setBlogs([]);
        }


        // if (status) {
        //   // const blogDatas = data.filter((x) => x.lang_code === activeLanguage);
        //   // setBlogs(blogDatas);
        // } else {
        //   console.log(message);
        //   setBlogs([]);
        // }
      }
    } catch (error) {
      console.log(error);
      setBlogs([]);
    }
  };

  useEffect(() => {
    getDatas();
  }, [activeLanguage]);

  return (
    <Layout>
      <div>
        <PageHeader
          page={t("global_header_blog")}
          home={t("global_header_home")}
          text={" - " + t("global_header_blog")}
          image="about-image"
        />
      </div>

      <div className="">
        <div className="md:flex flex-wrap justify-between mb-10 md:container md:mx-auto px-5 ">
          {blogs.map((blog, index) => {
            const small = process.env.REACT_APP_API_URL + blog.small;

            return (
              <BlogItem
                key={index}
                image={small}
                title={blog.title}
                date={moment(blog.created_at).format("DD MMM")}
                description={blog.description}
                href={"/blogs/" + blog.slug + "/" + blog.id}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
