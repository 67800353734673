export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const splitToken = (token) => {
  const tokenSplit = token.split(" ");
  return { header: tokenSplit[0], payload: tokenSplit[1] };
};

export const isTokenExpired = (tok) => Date.now() >= tok.exp * 1000;

export const textHash = (text) => {
  text = text + "_" + process.env.REACT_APP_SECRET_KEY;
  const reverse = text.split("").reverse().join("");
  const base64 = Buffer.from(reverse).toString("base64");
  return Buffer.from(base64).toString("base64");
};

export const textSalt = (base64) => {
  const firstSalt = Buffer.from(base64, "base64").toString("ascii");
  const text = Buffer.from(firstSalt, "base64").toString("ascii");

  return text.split("").reverse().join("");
};

// is dev
export const isDev = () => {
  return process.env.NODE_ENV === "development";
};

export const paymentUrl = (url = "") =>
  (isDev()
    ? process.env.REACT_APP_PAYMENT_TEST_URL
    : process.env.REACT_APP_PAYMENT_LIVE_URL) + url;

export const getMyIP = async () => {
  const response = await fetch("https://api.ipify.org?format=json");
  const data = await response.json();
  return data.ip;
};

// create localstorage
export const createLocalStorage = (storage_name, datas) => {
  localStorage.setItem(storage_name, JSON.stringify(datas));
  return true;
};

// add localstorage
export const addLocalStorage = (storage_name, addData) => {
  const datas = JSON.parse(localStorage.getItem(storage_name)) || [];
  datas.push(addData);
  localStorage.setItem(storage_name, JSON.stringify(datas));
  return datas;
};

// get localstorage
export const getLocalStorage = (storage_name) => {
  return JSON.parse(localStorage.getItem(storage_name)) || null;
};

// delete localstorage
export const deleteLocalStorage = (storage_name, deleteData) => {
  const datas = JSON.parse(localStorage.getItem(storage_name)) || [];
  const index = datas.indexOf(deleteData);
  if (index > -1) {
    datas.splice(index, 1);
  } else {
    return false;
  }
};

// clear localstorage
export const clearLocalStorage = (storage_name) => {
  localStorage.removeItem(storage_name);
};
