import React from "react";
import { Routes, Route } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HomeView from "./views/HomeView";
import AboutView from "./views/AboutView";
import DoctorsView from "./views/DoctorsView";
import DoctorsDetailView from "./views/DoctorsDetailView";
import BlogView from "./views/BlogView";
import BlogDetailView from "./views/BlogDetailView";
import ContactView from "./views/ContactView";
import KvkkView from "./views/KvkkView";
import LoginView from "./views/LoginView";
import CookiePolicy from "./views/CookiePolicy";
import AccountView from "./views/AccountView";
import PasswordProgress from "./views/PasswordProgress";
import MyAppointmentss from "./views/MyAppointmentss";
import ServiceView from "./views/ServiceView";
import ServiceDetailView from "./views/ServiceDetailView";
import UserVerify from "./views/UserVerify";
import PaymentConfirmation from "./views/PaymentConfirmation";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/hakkimizda" element={<AboutView />} />
        <Route path="/ekibimiz" element={<DoctorsView />} />
        <Route path="/ekibimiz/:slug/:id" element={<DoctorsDetailView />} />
        <Route path="/blog" element={<BlogView />} />
        <Route path="/hizmetlerimiz" element={<ServiceView />} />
        <Route
          path="/hizmetlerimiz/:slug/:id"
          element={<ServiceDetailView />}
        />
        <Route path="/blogs/:slug/:id" element={<BlogDetailView />} />
        <Route path="/iletisim" element={<ContactView />} />
        <Route path="/kvkk" element={<KvkkView />} />
        <Route path="/giris-yap" element={<LoginView />} />
        <Route path="/randevu-al" element={<LoginView />} />
        <Route path="/cerez-politikasi" element={<CookiePolicy />} />
        <Route path="/hesabim" element={<AccountView />} />
        <Route path="/sifre-islemleri" element={<PasswordProgress />} />
        <Route path="/randevularim" element={<MyAppointmentss />} />
        <Route path="/user-verify/:code" element={<UserVerify />} />
        <Route
          path="/payment-confirmation/:datas"
          element={<PaymentConfirmation />}
        />
      </Routes>
    </>
  );
}

export default App;
