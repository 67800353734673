import React from "react";
import { Link } from "react-router-dom";
import Button from "../base/button";

export default function DoctorsItem({ image, name, section, href = "#" }) {
  return (
    <>
      <Link to={href} className=" md:w-1/3 mb-10 p-3">
        <div className="mb-2 md:mb-0 text-main  rounded-2xl  shadow-2xl  ">
          <div className="pb-4">
            <img width="100%" src={image} />
          </div>
          <div className="mx-4 ">
            <div className="pb-4">
              <h2 className="pb-1 font-bold text-xl">{name}</h2>
              <p className="font-semibold text-lg">{section}</p>
            </div>
            <div className="flex justify-end pb-4 ">
              <Button
                href={href}
                text="Detay"
                type="bg-main"
                className=" md:w-1/3 "
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
