import React, { useEffect, useState } from "react";
import Layout from "../components/partials/layout";

import Button from "../components/base/button";
import PageHeader from "../components/elements/page-header";
import { useParams } from "react-router-dom";
import axios from "axios";
import DoctorsItem from "../components/elements/doctors-item";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export default function DoctorsDetailView() {
  const params = useParams();
  const { t } = useTranslation();
  const activeLanguage = i18n.language;

  const [doctor, setDoctor] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "doctors/front/" + params.id)
      .then((res) => {
        if (res.data && res.data.status) {
          const data = res.data.data;


          const description = data.description.find(
            (x) => x.lang_code === activeLanguage
          ).description;

          const sub_title = data.sub_titles.find(
            (x) => x.lang_code === activeLanguage
          ).sub_title;

          const image = data.original;

          const doctorData = {
            name: data.name_surname,
            sub_title,
            description,
            image: process.env.REACT_APP_API_URL + data.original,
          };
          setDoctor(doctorData);
        } else {
          console.log("error");
          setDoctor({});
        }
      })
      .catch((err) => {
        setDoctor({});
        console.error(err);
      });
  }, [activeLanguage]);

  return (
    <Layout>
      <PageHeader
        page={doctor.name}
        text={doctor.sub_title}
        image="doctors-image"
      />

      <div className="md:flex  md:container md:mx-auto px-5 md:mb-24">
        <div className="md:w-1/3 ">
          <img
            className="rounded-2xl mb-4 md:mb-0"
            width="100%"
            src={doctor.image}
            alt=""
          />
        </div>
        <div className="flex flex-col justify-between md:w-2/3 pl-1 md:pl-10">
          <div>
            <h2 className="font-bold text-main text-2xl">{doctor.name}</h2>
            <p className=" text-primary mb-5 md:mb-10">{doctor.sub_title} </p>
            <p className="text-main mb-10 md:mb-0">{doctor.description}</p>
          </div>
          <div className=" mb-5 md:mb-0"></div>
        </div>
      </div>
    </Layout>
  );
}
