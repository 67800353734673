import React, { useState, useEffect } from "react";
import Layout from "../components/partials/layout";
import {
  FaPhoneAlt,
  FaRegEnvelope,
  FaMapPin,
  BiCheckbox,
  MdCheckBox,
} from "react-icons/fa";
import PageHeader from "../components/elements/page-header";
import ContactCard from "../components/elements/contact-card";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ContactForm from "../components/elements/contact-form";
import axios from "axios";

export default function ContactView() {
  const [showMenu, setShowMenu] = useState(false);

  const { t } = useTranslation();

  const [infos, setInfos] = useState({});


  const getContactInfo = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "contact-infos/1")
      .then((res) => {
        if (res.data && res.data.status) {
          setInfos(res.data.data);
        }
      })
      .catch((err) => {
        setInfos({});
        console.log(err);
      });
  };

  useEffect(() => {
    getContactInfo();
  }, []);

  return (
    <Layout>
      <div>
        <PageHeader
          page={t("global_header_contact")}
          home={t("global_header_home")}
          text={"- " + t("global_header_contact")}
          image="about-image"
        />
      </div>
      <div className="md:container md:mx-auto px-5">
        <div className="md:flex mb-14">
          <ContactCard
            icon={<FaPhoneAlt className="text-3xl" />}
            text={t("contact_card_text_1")}
            number={infos.phone_1}
          />
          <ContactCard
            icon={<FaRegEnvelope className="text-3xl" />}
            text={t("contact_card_text_2")}
            number={infos.email}
          />
        </div>

        <div className=" flex md:flex-row  flex-col  w-full mb-20  ">
          <iframe
            src={infos && infos.map_code}
            className="rounded-xl flex   border-2 md:min-h-mapHeight h-96  order-2 md:order-1 md:w-1/2 m-2 "
          />

          <div className="md:w-1/2  order-1 md:order-2   p-2">
            {" "}
            <div>
              <div className="border-2 flex  items-center font-bold rounded-xl p-4 text-lg text-primary mb-4">
                <FaMapPin className="mr-4 text-2xl" />
                <p> {infos.address}</p>
              </div>

              <div className="border-2 rounded-xl p-6 w-full">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
